<template>
  <div class="container-form-login">
    <div>
      <v-btn @click="$router.push({ name: 'Signin' })" text>
        <v-icon class="icon-return" size="15px">
          mdi-arrow-left
        </v-icon>
        <span class="text-return mon-regular">{{
          texts.signinVendor.buttonReturn
        }}</span>
      </v-btn>
    </div>
    <div class="content-login">
      <div class="center-horizontall mt-9">
        <div @click="home" class="content-image">
          <img src="@/assets/logos/logo-bg-white.svg" alt="proplat-image.png" />
        </div>
      </div>
      <p class="text-title mon-bold mt-5">{{ texts.signinVendor.textTitle }}</p>
      <div class="pa-9 remove-pa-9">
        <v-text-field
          v-model="name"
          type="text"
          :label="texts.signinVendor.name.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinVendor.name.placeholder"
          persistent-placeholder
          maxLength="40"
        >
        </v-text-field>
        <v-text-field
          v-model="lastname"
          type="text"
          :label="texts.signinVendor.lastname.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinVendor.lastname.placeholder"
          persistent-placeholder
          maxLength="40"
        >
        </v-text-field>
        <v-text-field
          v-model="email"
          type="text"
          :label="texts.signinVendor.email.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinVendor.email.placeholder"
          persistent-placeholder
          maxLength="85"
        >
        </v-text-field>
        <v-text-field
          v-model="phone"
          type="text"
          :label="texts.signinVendor.phone.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinVendor.phone.placeholder"
          persistent-placeholder
          @keypress="soloNumbers"
          maxLength="13"
        >
        </v-text-field>
        <v-text-field
          v-model="enterpriseName"
          type="text"
          :label="texts.signinVendor.enterpriseName.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mb-5"
          :placeholder="texts.signinVendor.enterpriseName.placeholder"
          persistent-placeholder
          maxLength="60"
        >
        </v-text-field>
        <div class="display-flex align-items-center">
          <!-- country -->
          <v-select
            v-model="country"
            item-text="sName"
            item-value="sCountryId"
            :items="itemsCountries"
            :label="texts.signinVendor.country.label"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular mr-1"
            style="width: 50%"
            :placeholder="texts.signinVendor.country.placeholder"
            @input="selecState"
          >
          </v-select>
          <!-- state -->
          <v-select
            v-model="userState"
            item-text="sName"
            item-value="sStateId"
            :items="itemsStates"
            :label="texts.signinVendor.state.label"
            outlined
            color="#000000"
            dense
            class="global-inputs mon-regular ml-1"
            style="width: 50%"
            :placeholder="texts.signinVendor.state.placeholder"
            @input="selecState"
          >
          </v-select>
        </div>
        <v-text-field
          v-model="password"
          :label="texts.signinVendor.password.label"
          :append-icon="bShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowPassword = !bShowPassword)"
          :type="bShowPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.signinVendor.password.placeholder"
          persistent-placeholder
        >
        </v-text-field>
        <v-text-field
          v-model="confirmPassword"
          :label="texts.signinVendor.confirmPassword.label"
          :append-icon="bShowConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="() => (bShowConfirmPassword = !bShowConfirmPassword)"
          :type="bShowConfirmPassword ? 'text' : 'password'"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular mt-5"
          :placeholder="texts.signinVendor.confirmPassword.placeholder"
          persistent-placeholder
        >
        </v-text-field>
      </div>
      <div class="pl-9 pr-9 mb-9 remove-margin-laterals">
        <div class="display-flex align-items-center">
          <v-btn
            @click="goSignin()"
            elevation="0"
            class="button-cancel mon-regular"
          >
            {{ texts.signinVendor.buttonCancel }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="signinVendors"
            elevation="0"
            :disabled="!validateForm"
            :loading="bLoading"
            class="button-login mon-bold"
          >
            {{ texts.signinVendor.buttonRegister }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  name: "SigninVendorFormLayout",
  data() {
    return {
      //VARIABLES
      itemsCountries: [],
      itemsStates: [],
      name: "",
      lastname: "",
      email: "",
      phone: "",
      enterpriseName: "",
      password: "",
      confirmPassword: "",
      userState: "",
      country: "",
      bShowPassword: false,
      bShowConfirmPassword: false,
      bLoading: false,
    };
  },
  beforeMount() {
    this.getCountries();
  },
  methods: {
    goSignin() {
      this.$router.push({ name: "Signin" });
    },
    signinVendors: function() {
      const self = this;
      this.bLoading = true;

      if (
        this.password !== this.confirmPassword ||
        this.confirmPassword !== this.password
      ) {
        this.bLoading = false;
        this.mixError(self.texts.signinVendor.messageErrorPassword);
      } else {
        const payload = {
          oEnterprise: {
            sName: this.enterpriseName,
            sLocationStateId: this.userState,
          },
          oVendor: {
            sName: this.name,
            sLastname: this.lastname,
            sEmail: this.email,
            sPhoneNumber: this.phone,
            sPassword: this.confirmPassword,
          },
        };
        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises`,
          payload
        )
          .then((response) => {
            this.bLoading = false;
            this.mixSuccess(response.data.message);
            setTimeout(function() {
              window.location.href = URIVendors;
            }, 2000);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    getCountries: function() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.itemsCountries = response.data.results.filter(
            (e) => e.sCountryId !== "4ea76b09-621b-4518-814d-62582395d9f1"
          );
          // Mexico
          this.country = response.data.results[0].sCountryId;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function() {
      this.state = "";
      this.getStates();
    },
    getStates: function() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.country}/states`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          // this.state = response.data.results[0].sStateId;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    home: function() {
      this.$router
        .push({
          name: "Home",
        })
        .catch(() => {});
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    validateForm: function() {
      return (
        this.name !== "" &&
        this.lastname !== "" &&
        this.email !== "" &&
        this.phone !== "" &&
        this.enterpriseName !== "" &&
        this.password !== "" &&
        this.confirmPassword !== "" &&
        this.userState !== "" &&
        this.country !== ""
      );
    },
  },
};
</script>

<style scoped>
.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.button-create-account {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  background-color: #ffffff !important;
  text-transform: initial;
  font-size: 14px;
  width: 100%;
}

.link-forgot-password {
  text-transform: initial;
  color: #0049b7;
  text-decoration: underline;
  letter-spacing: 0px;
}

.button-login {
  width: 47%;
  height: 40px !important;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 13px;
  text-transform: initial;
}

.button-login:disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}

.button-login:disabled::before {
  pointer-events: initial;
}

.button-cancel {
  width: 47%;
  height: 40px !important;
  background-color: transparent !important;
  opacity: 1;
  font-size: 13px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  color: #283c4d;
  border-radius: 10px;
  text-transform: initial;
}

.text-title {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
}

.container-form-login {
  width: 100%;
  /* height: 100%; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* padding-top: 100px; */
  /* background-color: khaki; */
}

.center-horizontall {
  display: flex;
  justify-content: center;
}

.content-image {
  width: 260px;
  height: 45px;
}

.content-image:hover {
  cursor: pointer;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

@media (max-width: 720px) {
  .content-login {
    width: 100%;
  }
  .text-title {
    font-size: 20px;
  }
  .remove-pa-9 {
    padding: 15px !important;
  }
  .remove-margin-laterals {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
</style>
